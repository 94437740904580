import { action, observable, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import moment from 'moment'
import { RefariDTOTypes } from '@refari-frontend/types'

import API from 'src/utils/API'
import standAloneStore from './StandAloneStore'
import candidatesMainStore from './CandidatesMainStore'
import alertMessages from 'src/constants/alertMessages'
import Logger from 'src/utils/Logger'
import { ApiRoutes } from 'src/utils/Urls'
import { CandidateAdCreateSchema } from 'src/types/APITypes'
import type {
  APIResponse,
  CandidateAdsListResponse,
  CandidateAdsDetailsSchema
} from 'src/types/APITypes'
import CandidateAdsListModel, {
  type ICandidateAdsListModel
} from 'src/models/CandidateAds/ListModel'
import CandidateAdsDetailsModel, {
  type ICandidateAdsDetailsModel
} from 'src/models/CandidateAds/DetailsModel'
import EnquiriesModel, { IEnquiriesModel } from 'src/models/Enquiries'
import { wrapIframesWithDiv } from 'src/utils/helpers'

type ListParams = {
  ordering?: string
  search?: string
  page?: number
  referrer?: string
  candidate_id?: string
  is_active?: boolean
}

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

type CreateCandidateAdAdditionalArgs = {
  candidateId: number
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
  canAddCandidate: boolean
  candidateAdMode?: CandidateAdMode
  candidateDraftId?: string
  cb?: () => void
}

type CreateCandidateDraftAdAdditionalArgs = {
  candidateId?: number | string
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
  candidateAdId?: string
  // candidateAdMode?: CandidateAdMode
  cb?: () => void
}

type UpdateCandidateAdAdditionalArgs = {
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
}

type SortConfig = {
  param: string
  direction: boolean // true means ascending order
}

type Filters = Partial<ListParams>

type CandidateInfoDisplayState = {
  showInfo: boolean
  triggerSource?: 'auto' | 'manual'
}

type CandidateInfoFromPartnerActions = {
  id?: number
  firstName: string
  lastName: string
  email: string
  externalID: string
}

type CandidateAdMode = 'create' | 'edit' | 'repost' | 'draft'

type OpenEditCandidateAdArgs = {
  candidateAdMode: CandidateAdMode
}

export type AIGeneratedCandidateAdResponse =
  RefariDTOTypes['/dashboard/candidate-ads/generate-ai/']['post']['responses']['200']['content']['application/json']

export type GetCandidateFiles =
  RefariDTOTypes['/dashboard/candidate-ads/files/']['post']['responses']['200']['content']['application/json']

export type ICandidateAdsStore = {
  isListLoading: boolean
  isDetailsLoading: boolean
  pagination: Pagination
  candidateAds: ICandidateAdsListModel[]
  enquiries: IEnquiriesModel[]
  activeCandidateAdId: string
  candidateAdDetails: ICandidateAdsDetailsModel | null
  sortConfig: SortConfig
  search: string
  isActive: boolean
  candidateAdIdFromQueryData?: string
  editCandidateAd: boolean
  createCandidateAdDeleteConfirmationModal: boolean
  editCandidateAdDeleteConfirmationModal: boolean
  isRepostConfirmationModalOpen: boolean
  isOpenCandidateAdDetailsDrawer: boolean
  isCandidateAdDetailsUpdated: boolean
  isOpenSalaryRangeCreateModal: boolean
  isOpenSalaryRangeEditModal: boolean
  isOpenCandidateAdCreateDialog: boolean
  isOpenExpiryConfirmationModal: boolean
  selectedCandidateId: number
  candidateInfoDisplayState: CandidateInfoDisplayState
  candidateInfoFromPartnerActions: CandidateInfoFromPartnerActions | null
  shouldShowPagination: boolean
  candidateAdMode?: CandidateAdMode
  filterCandidateAds: (
    filters: Filters,
    onSuccess?: () => void
  ) => Promise<void>
  filterEnquiries: (filters: Filters, onSuccess?: () => void) => Promise<void>
  sortList: (sortKey: string) => Promise<void>
  createCandidateAd: (
    form: any,
    args: CreateCandidateAdAdditionalArgs
  ) => Promise<void>
  saveOrEditCandidateAdDraft: (
    form: any,
    args: CreateCandidateDraftAdAdditionalArgs
  ) => Promise<void>
  partialUpdateCandidateAd: (
    form: any,
    args: UpdateCandidateAdAdditionalArgs
  ) => Promise<void>
  expireCandidateAd: (id: string) => Promise<void>
  fetchCandidateAds: (onSuccess?: () => void) => Promise<void>
  setActiveCandidateAdId: (id: string) => void
  fetchCandidateAdDetails: (
    cb?: (candidate: ICandidateAdsDetailsModel | null) => void
  ) => Promise<void>
  setCandidateAdIdFromQueryData: (candidateAdId: string) => void
  resetcandidateAds: () => void
  resetCandidateAdIdFromQueryData: () => void
  fetchEnquiries: (onSuccess?: () => void) => Promise<void>
  resetEnquiries: () => void
  openEditCandidateAd: (
    openEditCandidateAdArgs?: OpenEditCandidateAdArgs
  ) => void
  closeEditCandidateAd: () => void
  openCreateCandidateAdDeleteConfirmationModal: () => void
  closeCreateCandidateAdDeleteConfirmationModal: () => void
  openEditCandidateAdDeleteConfirmationModal: () => void
  openRepostConfirmationModal: () => void
  closeEditCandidateAdDeleteConfirmationModal: () => void
  closeRepostConfirmationModal: () => void
  openCandidateAdDetailsDrawer: () => void
  closeCandidateAdDetailsDrawer: () => void
  openSalaryRangeCreateModal: () => void
  closeSalaryRangeCreateModal: () => void
  openSalaryRangeEditModal: () => void
  closeSalaryRangeEditModal: () => void
  openCandidateAdCreateDialog: () => void
  closeCandidateAdCreateDialog: () => void
  openCandidateAdDraftEditDialog: () => void
  closeCandidateAdDraftEditDialog: () => void
  setSelectedCandidateId: (candidateId: number) => void
  resetSelectedCandidateId: () => void
  setCandidateInfoDisplayState: (
    candidateInfoDisplayState: CandidateInfoDisplayState
  ) => void
  setCandidateInfoFromPartnerActions: (
    candidateInfo: CandidateInfoFromPartnerActions
  ) => void
  resetCandidateInfoFromPartnerActitions: () => void
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  setIsActive: (value: boolean) => void
  openExpiryConfirmationModal: () => void
  closeExpiryConfirmationModal: () => void
  generateCandidateAdvertContent: (
    file: File
  ) => Promise<AIGeneratedCandidateAdResponse | undefined>
  getCandidateFiles: (candidate: {
    id: number | string
    isExternalID: boolean
  }) => Promise<GetCandidateFiles | null>
  // fix return type
  generateCandidateAdvertContentWithFileID: (
    fileId: string | number
  ) => Promise<AIGeneratedCandidateAdResponse | undefined>

  // fix return type
  generateCandidateAdvertContentWithExternalID: (
    fileExtenalID: string,
    candidateExternalID: string
  ) => Promise<AIGeneratedCandidateAdResponse | undefined>
}

class CandidateAdsStore implements ICandidateAdsStore {
  @observable isListLoading = false
  @observable isDetailsLoading = false
  @observable pagination: ICandidateAdsStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable candidateAds: ICandidateAdsStore['candidateAds'] = []
  @observable enquiries: ICandidateAdsStore['enquiries'] = []
  @observable activeCandidateAdId = ''
  @observable candidateAdDetails: ICandidateAdsStore['candidateAdDetails'] =
    null
  @observable sortConfig: ICandidateAdsStore['sortConfig'] = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable isActive = true
  @observable
  candidateAdIdFromQueryData: ICandidateAdsStore['candidateAdIdFromQueryData']
  @observable editCandidateAd = false
  @observable createCandidateAdDeleteConfirmationModal = false
  @observable editCandidateAdDeleteConfirmationModal = false
  @observable isRepostConfirmationModalOpen = false
  @observable isOpenCandidateAdDetailsDrawer = false
  @observable isCandidateAdDetailsUpdated = false
  @observable isOpenSalaryRangeCreateModal = false
  @observable isOpenSalaryRangeEditModal = false
  @observable isOpenCandidateAdCreateDialog = false
  @observable selectedCandidateId = 0
  @observable candidateInfoDisplayState: CandidateInfoDisplayState = {
    showInfo: false,
    triggerSource: undefined
  }
  @observable
  candidateInfoFromPartnerActions: ICandidateAdsStore['candidateInfoFromPartnerActions'] =
    null
  @observable candidateAdMode: ICandidateAdsStore['candidateAdMode'] = undefined
  @observable isOpenExpiryConfirmationModal = false

  @action
  openExpiryConfirmationModal: ICandidateAdsStore['openExpiryConfirmationModal'] =
    () => {
      this.isOpenExpiryConfirmationModal = true
    }

  @action
  closeExpiryConfirmationModal: ICandidateAdsStore['closeExpiryConfirmationModal'] =
    () => {
      this.isOpenExpiryConfirmationModal = false
    }

  @action
  setCandidateInfoFromPartnerActions: ICandidateAdsStore['setCandidateInfoFromPartnerActions'] =
    (candidateInfo) => {
      if (candidateInfo) {
        const info: CandidateInfoFromPartnerActions = {
          id: undefined,
          firstName: '',
          lastName: '',
          email: '',
          externalID: ''
        }

        this.candidateInfoFromPartnerActions = info

        this.candidateInfoFromPartnerActions.id = candidateInfo.id
        this.candidateInfoFromPartnerActions.firstName = candidateInfo.firstName
        this.candidateInfoFromPartnerActions.lastName = candidateInfo.lastName
        this.candidateInfoFromPartnerActions.email = candidateInfo.email
        this.candidateInfoFromPartnerActions.externalID =
          candidateInfo.externalID
      }
    }

  @action
  resetCandidateInfoFromPartnerActitions: ICandidateAdsStore['resetCandidateInfoFromPartnerActitions'] =
    () => {
      this.candidateInfoFromPartnerActions = null
    }

  @action
  setCandidateInfoDisplayState: ICandidateAdsStore['setCandidateInfoDisplayState'] =
    (displayState) => {
      this.candidateInfoDisplayState.showInfo = displayState.showInfo
      this.candidateInfoDisplayState.triggerSource = displayState.triggerSource
    }

  @action setSelectedCandidateId: ICandidateAdsStore['setSelectedCandidateId'] =
    (candidateId) => {
      this.selectedCandidateId = candidateId
    }

  @action
  resetSelectedCandidateId: ICandidateAdsStore['resetSelectedCandidateId'] =
    () => {
      this.selectedCandidateId = 0
    }

  @action
  openCandidateAdCreateDialog: ICandidateAdsStore['openCandidateAdCreateDialog'] =
    () => {
      /**
       * @note reset location from standalone store before opening candidate ad create dialog
       */
      standAloneStore.resetLocationFieldIds()
      this.isOpenCandidateAdCreateDialog = true
    }

  @action
  closeCandidateAdCreateDialog: ICandidateAdsStore['closeCandidateAdCreateDialog'] =
    () => {
      this.isOpenCandidateAdCreateDialog = false
    }

  @action
  openCandidateAdDraftEditDialog: ICandidateAdsStore['openCandidateAdDraftEditDialog'] =
    () => {
      /**
       * @note make sure we've already set the active candidate ID
       */
      this.isOpenCandidateAdCreateDialog = true
      this.candidateAdMode = 'draft'
    }

  @action
  closeCandidateAdDraftEditDialog: ICandidateAdsStore['closeCandidateAdDraftEditDialog'] =
    () => {
      this.isOpenCandidateAdCreateDialog = false
      this.candidateAdMode = undefined
      this.candidateAdDetails = null
    }

  @action
  openSalaryRangeCreateModal: ICandidateAdsStore['openSalaryRangeCreateModal'] =
    () => {
      this.isOpenSalaryRangeCreateModal = true
    }

  @action
  closeSalaryRangeCreateModal: ICandidateAdsStore['closeSalaryRangeCreateModal'] =
    () => {
      this.isOpenSalaryRangeCreateModal = false
    }

  @action
  openSalaryRangeEditModal: ICandidateAdsStore['openSalaryRangeEditModal'] =
    () => {
      this.isOpenSalaryRangeEditModal = true
    }

  @action
  closeSalaryRangeEditModal: ICandidateAdsStore['closeSalaryRangeEditModal'] =
    () => {
      this.isOpenSalaryRangeEditModal = false
    }

  @action
  openCandidateAdDetailsDrawer: ICandidateAdsStore['openCandidateAdDetailsDrawer'] =
    () => {
      this.isOpenCandidateAdDetailsDrawer = true
    }

  @action
  closeCandidateAdDetailsDrawer: ICandidateAdsStore['closeCandidateAdDetailsDrawer'] =
    () => {
      this.isOpenCandidateAdDetailsDrawer = false
    }

  @action
  openEditCandidateAd: ICandidateAdsStore['openEditCandidateAd'] = (
    editArgs
  ) => {
    this.editCandidateAd = true
    this.candidateAdMode = editArgs?.candidateAdMode
  }

  @action
  closeEditCandidateAd: ICandidateAdsStore['closeEditCandidateAd'] = () => {
    this.editCandidateAd = false
    this.candidateAdMode = undefined
  }

  @action
  openCreateCandidateAdDeleteConfirmationModal: ICandidateAdsStore['openCreateCandidateAdDeleteConfirmationModal'] =
    () => {
      this.createCandidateAdDeleteConfirmationModal = true
    }

  @action
  closeCreateCandidateAdDeleteConfirmationModal: ICandidateAdsStore['closeCreateCandidateAdDeleteConfirmationModal'] =
    () => {
      this.createCandidateAdDeleteConfirmationModal = false
    }

  @action
  openEditCandidateAdDeleteConfirmationModal: ICandidateAdsStore['openEditCandidateAdDeleteConfirmationModal'] =
    () => {
      this.editCandidateAdDeleteConfirmationModal = true
    }

  @action
  openRepostConfirmationModal: ICandidateAdsStore['openRepostConfirmationModal'] =
    () => {
      this.isRepostConfirmationModalOpen = true
    }

  @action
  closeEditCandidateAdDeleteConfirmationModal: ICandidateAdsStore['closeEditCandidateAdDeleteConfirmationModal'] =
    () => {
      this.editCandidateAdDeleteConfirmationModal = false
    }

  @action
  closeRepostConfirmationModal: ICandidateAdsStore['closeRepostConfirmationModal'] =
    () => {
      this.isRepostConfirmationModalOpen = false
    }

  @action
  setCandidateAdIdFromQueryData: ICandidateAdsStore['setCandidateAdIdFromQueryData'] =
    (candidateAdId: string) => {
      this.candidateAdIdFromQueryData = candidateAdId
    }

  @action
  private setIsListLoading = (state: boolean): void => {
    this.isListLoading = state
  }

  @action
  private setIsDetailsLoading = (state: boolean): void => {
    this.isDetailsLoading = state
  }

  @action
  createCandidateAd: ICandidateAdsStore['createCandidateAd'] = async (
    form,
    {
      candidateId,
      selecetedKeysGetter,
      canAddCandidate,
      candidateAdMode,
      candidateDraftId,
      cb
    }
  ) => {
    const data = { ...form.data }

    const isDraftPublish = candidateAdMode === 'draft' && candidateDraftId

    const hasCandidateId =
      this.candidateInfoFromPartnerActions?.id ||
      this.selectedCandidateId ||
      candidateId

    const dataTobeSentToServer: CandidateAdCreateSchema = {
      title: data.title,
      description: wrapIframesWithDiv(data.description),
      ...(hasCandidateId && {
        candidate: candidateId
      }),
      summary: data.summary,
      ...(!hasCandidateId && {
        candidate_data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email
        }
      }),
      consultant: Number(data.consultant),
      locations: Array.isArray(data.locations)
        ? data.locations.map((location: any) => {
            return location?.id
          })
        : [],
      worktypes: selecetedKeysGetter(
        data.worktypes.map((worktype: string | Record<string, any>) => {
          if (typeof worktype === 'string') {
            return worktype
          }

          return worktype.name
        }),
        standAloneStore.getWorkTypesList
      ),
      skills: selecetedKeysGetter(
        data.skills.map((skill: string | Record<string, any>) => {
          if (typeof skill === 'string') {
            return skill
          }

          return skill.name
        }),
        standAloneStore.getCandidateSkillsList
      ),
      salary_rate_period:
        data.salary_rate_period === ''
          ? null
          : data.salary_rate_period.toLowerCase(),
      salary_currency: data.salary_currency,
      // @ts-ignore incorrect type from API
      salary_rate_max:
        data.salary_rate_period === '' ? null : Number(data.salary_rate_max),
      // @ts-ignore incorrect type from API
      salary_rate_min:
        data.salary_rate_period === '' ? null : Number(data.salary_rate_min),
      expired_at: moment(data.expired_at).format()
    }

    try {
      const response = isDraftPublish
        ? await API.postData(
            ApiRoutes.dashboard.candidateAd.publishDraft(candidateDraftId),
            dataTobeSentToServer
          )
        : await API.postData(
            ApiRoutes.dashboard.candidateAd.create,
            dataTobeSentToServer
          )

      if (response.status === 201) {
        // repost action is happening on candidate ad page
        // create action in happening on candidate page
        if (candidateAdMode === 'repost') {
          console.log('repost response', response)
          toast.success(alertMessages.candidateAd.repost.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else if (candidateAdMode === 'draft') {
          toast.success(alertMessages.candidateAd.publishDraft.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else {
          toast.success(alertMessages.candidateAd.create.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeCandidateAdCreateDialog()

          if (canAddCandidate) {
            this.fetchCandidateAds()

            this.setCandidateInfoDisplayState({
              showInfo: false,
              triggerSource: undefined
            })

            this.resetSelectedCandidateId()
          } else {
            candidatesMainStore.fetchCandidates()
          }
        }
        typeof cb === 'function' && cb()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error) {
      Logger.error(error as any)

      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @action
  saveOrEditCandidateAdDraft = async (
    form: any,
    args: CreateCandidateDraftAdAdditionalArgs
  ) => {
    const data = { ...form.data }
    const { selecetedKeysGetter } = args

    const hasCandidateId =
      this.candidateInfoFromPartnerActions?.id ||
      this.selectedCandidateId ||
      args.candidateId

    const dataTobeSentToServer: CandidateAdCreateSchema = {
      title: data.title,
      description: data.description ? wrapIframesWithDiv(data.description) : '',
      ...(hasCandidateId && {
        candidate: args.candidateId
      }),
      ...(!hasCandidateId && {
        candidate_data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email
        }
      }),
      summary: data.summary,
      consultant: data.consultant && Number(data.consultant),
      locations: Array.isArray(data.locations)
        ? data.locations.map((location: any) => {
            return location?.id
          })
        : [],
      worktypes: Array.isArray(data.worktypes)
        ? selecetedKeysGetter(
            data.worktypes.map((worktype: string | Record<string, any>) => {
              if (typeof worktype === 'string') {
                return worktype
              }

              return worktype.name
            }),
            standAloneStore.getWorkTypesList
          )
        : [],
      skills: Array.isArray(data.skills)
        ? selecetedKeysGetter(
            data.skills.map((skill: string | Record<string, any>) => {
              if (typeof skill === 'string') {
                return skill
              }

              return skill.name
            }),
            standAloneStore.getCandidateSkillsList
          )
        : [],
      salary_rate_period: !data.salary_rate_period
        ? null
        : data.salary_rate_period.toLowerCase(),
      salary_currency: data.salary_currency,
      // @ts-ignore incorrect type from API
      salary_rate_max: !data.salary_rate_period
        ? null
        : Number(data.salary_rate_max),
      // @ts-ignore incorrect type from API
      salary_rate_min: !data.salary_rate_period
        ? null
        : Number(data.salary_rate_min),
      ...(data.expired_at && {
        expired_at: moment(data.expired_at).format()
      })
    }
    console.log('data to be send to server', dataTobeSentToServer)
    try {
      const response = args.candidateAdId
        ? await API.patchData(
            ApiRoutes.dashboard.candidateAd.editDraft(args.candidateAdId),
            dataTobeSentToServer
          )
        : await API.postData(
            ApiRoutes.dashboard.candidateAd.createDraft,
            dataTobeSentToServer
          )
      if (response.status === 201 || response.status === 200) {
        args.candidateAdId
          ? toast.success(alertMessages.candidateAd.editDraft.success)
          : toast.success(alertMessages.candidateAd.createDraft.success)
        standAloneStore.resetLocationFieldIds()
        standAloneStore.resetLocationRecord()

        this.closeCandidateAdCreateDialog()
        this.resetSelectedCandidateId()
        this.candidateAdDetails = null

        this.fetchCandidateAds()
        typeof args.cb === 'function' && args.cb()
      }
    } catch (error) {
      Logger.error(error as any)

      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {
      is_active: this.isActive
    }

    if (this.sortConfig.param) {
      params.ordering = this.sortConfig.direction
        ? this.sortConfig.param
        : `-${this.sortConfig.param}`
    }

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    if (this.search) {
      params.search = this.search
    }

    if (this.candidateAdIdFromQueryData) {
      params.candidate_id = this.candidateAdIdFromQueryData
    }

    return params
  }

  @action
  fetchCandidateAds: ICandidateAdsStore['fetchCandidateAds'] = async (
    onSuccess
  ) => {
    try {
      this.setIsListLoading(true)

      const response: APIResponse<CandidateAdsListResponse> = await API.getData(
        ApiRoutes.dashboard.candidateAd.list,
        this.getParams
      )

      if (!response.data) {
        throw new Error("FetchCandidateAds API Response:: doesn't contain data")
      }

      const candidatesResponseArray = response.data.results

      runInAction(() => {
        this.candidateAds = candidatesResponseArray.map(
          (item) => new CandidateAdsListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })

        onSuccess && onSuccess()
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsListLoading(false)
    }
  }

  @action
  fetchCandidateAdDetails: ICandidateAdsStore['fetchCandidateAdDetails'] =
    async (cb) => {
      try {
        const isDraft =
          this.candidateAds.filter(
            (candidate) => candidate.id === this.activeCandidateAdId
          )[0]?.status === 'draft'
        this.setIsDetailsLoading(true)

        const response: APIResponse<CandidateAdsDetailsSchema> =
          await API.getData(
            isDraft
              ? ApiRoutes.dashboard.candidateAd.draftDetails(
                  this.activeCandidateAdId
                )
              : ApiRoutes.dashboard.candidateAd.details(
                  this.activeCandidateAdId
                )
          )

        const { data } = response

        if (!data) {
          throw new Error(
            "FetchCandidateAdDetails API Response:: doesn't contain data"
          )
        }

        runInAction(() => {
          this.candidateAdDetails = new CandidateAdsDetailsModel(data)

          this.isCandidateAdDetailsUpdated = false

          standAloneStore.locationFieldIds =
            this.candidateAdDetails.locations.map(
              (locationModel) => locationModel.fieldId
            )

          this.candidateAdDetails.locations.forEach((locationModel, index) => {
            standAloneStore.updateLocationRecord({
              fieldId: standAloneStore.locationFieldIds[index],
              parentLocationId: String(locationModel.location),
              hasSubLocation: false
            })
          })
          typeof cb === 'function' && cb(this.candidateAdDetails)
        })
      } catch (error) {
        Logger.error(error as any)
      } finally {
        this.setIsDetailsLoading(false)
      }
    }

  @action
  partialUpdateCandidateAd: ICandidateAdsStore['partialUpdateCandidateAd'] =
    async (form, { selecetedKeysGetter }) => {
      const data = { ...form.data }

      const dataTobeSentToServer: Partial<CandidateAdCreateSchema> = {
        title: data.title,
        locations: Array.isArray(data.locations)
          ? data.locations.map((location: any) => {
              return location?.id
            })
          : [],
        consultant: Number(data.consultant),
        salary_rate_period:
          data.salary_rate_period === ''
            ? null
            : data.salary_rate_period.toLowerCase(),
        salary_currency: data.salary_currency,
        // @ts-ignore incorrect type from API
        salary_rate_max:
          data.salary_rate_period === '' ? null : Number(data.salary_rate_max),
        // @ts-ignore incorrect type from API
        salary_rate_min:
          data.salary_rate_period === '' ? null : Number(data.salary_rate_min),
        description: wrapIframesWithDiv(data.description),
        summary: data.summary,
        expired_at: moment(data.expired_at).format(),
        worktypes: selecetedKeysGetter(
          data.worktypes.map((worktype: string | Record<string, any>) => {
            if (typeof worktype === 'string') {
              return worktype
            }

            return worktype.name
          }),
          standAloneStore.getWorkTypesList
        ),
        skills: selecetedKeysGetter(
          data.skills.map((skill: string | Record<string, any>) => {
            if (typeof skill === 'string') {
              return skill
            }

            return skill.name
          }),
          standAloneStore.getCandidateSkillsList
        )
      }

      try {
        const response = await API.patchData(
          ApiRoutes.dashboard.candidateAd.partialUpdate(
            this.activeCandidateAdId
          ),
          dataTobeSentToServer
        )

        if (response.status === 200) {
          toast.success(alertMessages.candidateAd.update.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      } catch (error) {
        Logger.error(error as any)

        toast.error(alertMessages.genericErrorMessage)
      }
    }

  @action
  expireCandidateAd: ICandidateAdsStore['expireCandidateAd'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.candidateAd.expire(id)
      )

      if (response.status === 200) {
        toast.success(alertMessages.candidateAd.expire.success)

        this.fetchCandidateAds()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error) {
      Logger.error(error as any)

      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @action
  sortList: ICandidateAdsStore['sortList'] = async (sortKey: string) => {
    this.pagination.currentPage = 1

    if (this.sortConfig.param === sortKey) {
      /**
       * change the direction
       */
      this.sortConfig = {
        param: sortKey,
        direction: !this.sortConfig.direction
      }
    } else {
      this.sortConfig = {
        param: sortKey,
        direction: false
      }
    }
    this.fetchCandidateAds()
  }

  @action
  filterCandidateAds: ICandidateAdsStore['filterCandidateAds'] = async (
    filters,
    onSuccess
  ) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.is_active !== 'undefined' &&
      this.isActive !== filters.is_active
    ) {
      this.isActive = filters.is_active
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchCandidateAds(onSuccess)
  }

  @action
  filterEnquiries: ICandidateAdsStore['filterEnquiries'] = async (
    filters,
    onSuccess
  ) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchEnquiries(onSuccess)
  }

  @action
  setActiveCandidateAdId: ICandidateAdsStore['setActiveCandidateAdId'] = (
    id
  ) => {
    this.activeCandidateAdId = id
  }

  @action
  resetcandidateAds: ICandidateAdsStore['resetcandidateAds'] = () => {
    this.candidateAds = []
  }

  @action
  resetCandidateAdIdFromQueryData: ICandidateAdsStore['resetCandidateAdIdFromQueryData'] =
    () => {
      this.candidateAdIdFromQueryData = undefined
    }

  @action
  fetchEnquiries: ICandidateAdsStore['fetchEnquiries'] = async (onSuccess) => {
    try {
      this.setIsListLoading(true)

      const response: APIResponse<
        RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']
      > = await API.getData(ApiRoutes.dashboard.enquiries.list, this.getParams)

      if (!response.data) {
        throw new Error("FetchCandidateAds API Response:: doesn't contain data")
      }

      const enqiriesResponseArray = response.data.results

      runInAction(() => {
        this.enquiries = enqiriesResponseArray.map(
          (item) => new EnquiriesModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })

        onSuccess && onSuccess()
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsListLoading(false)
    }
  }

  @action
  resetEnquiries: ICandidateAdsStore['resetEnquiries'] = () => {
    this.enquiries = []
  }

  @action
  setPaginationParams: ICandidateAdsStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  generateCandidateAdvertContent: ICandidateAdsStore['generateCandidateAdvertContent'] =
    async (file) => {
      try {
        const formData = new FormData()
        formData.append('resume', file)

        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd.generateCandidateAdContent,
            formData
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Error with connection to AI service'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  generateCandidateAdvertContentWithExternalID: ICandidateAdsStore['generateCandidateAdvertContentWithExternalID'] =
    async (fileExternalID, candidateExternalID) => {
      try {
        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd
              .generateCandidateAdContentWithExternalFileID,
            {
              file_external_id: fileExternalID,
              candidate_external_id: candidateExternalID
            }
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Something went wrong, Try Again Later'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  generateCandidateAdvertContentWithFileID: ICandidateAdsStore['generateCandidateAdvertContentWithFileID'] =
    async (fileID) => {
      try {
        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd
              .generateCandidateAdContentWithFileId,
            { file_id: fileID }
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Something went wrong, Try Again Later'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  getCandidateFiles: ICandidateAdsStore['getCandidateFiles'] = async (
    candidate
  ) => {
    try {
      const payload = candidate.isExternalID
        ? { candidate_external_id: candidate.id }
        : { candidate_id: candidate.id }
      const response: APIResponse<GetCandidateFiles> = await API.postData(
        ApiRoutes.dashboard.candidateAd.getCandidateFiles,
        payload
      )
      const { data } = response
      return data
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  @action
  setIsActive = (value: boolean) => {
    this.isActive = value
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }
}

export default new CandidateAdsStore()
