export const baseUrls = {
  development: 'https://api.dev.refari.co/',
  staging: 'https://api.stg.refari.co/',
  production: 'https://api.refari.co/',
  production_new: 'https://api.refari.co/',
  development_old: 'https://api.dev.referrer.io/',
  static: `${process.env.PUBLIC_URL}/static/`
}

export const baseServerURLs = () => {
  switch (process.env.REACT_APP_BUILD_MODE) {
    case 'staging':
      return baseUrls.staging
    case 'production':
      return baseUrls.production
    case 'production_new':
      return baseUrls.production_new
    case 'development_old':
      return baseUrls.development_old
    default:
      /* development */
      return baseUrls.development
  }
}

function kebabCase(str) {
  if (typeof str !== 'string') throw new Error('Not a String in kebabcase')
  const result = str.replace(
    /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g,
    (match) => '-' + match.toLowerCase()
  )
  return str[0] === str[0].toUpperCase() ? result.substring(1) : result
}
export const widgetUrls = {
  jobDetailPage: (baseUrl, slug) => `${baseUrl}?job=${slug}#detail`,
  candidateAdDetailPage: (baseUrl, candidateAdId) =>
    `${baseUrl}?candidateAdId=${candidateAdId}#detail`,
  jobListPage: (baseUrl) => `${baseUrl}#list`,
  draftPage: (baseUrl) => `${baseUrl}#consultant-preview`,
  consultantPage: (baseUrl, consultantName, consultantId) =>
    `${baseUrl}?consultant=${kebabCase(consultantName).replace(
      ' ',
      ''
    )}_${consultantId}#consultant-profile`,
  pitchMePreviewPage: (baseUrl, consultantName, consultantId, draftID) =>
    `${baseUrl}?consultant=${kebabCase(consultantName).replace(
      ' ',
      ''
    )}_${consultantId}&draft_id=${draftID}#pitch-me-preview`
}

const baseUrlConstants = {
  development: {
    backend: 'https://api.dev.refari.co/',
    frontend: 'https://dev.refari.co/', // website
    widget: 'https://widget-dev.refari.co/' // widget
  },
  development_old: {
    backend: 'https://api.dev.referrer.io/',
    frontend: 'https://dev.referrer.io/', // website
    widget: 'https://widget-dev.referrer.io/' // widget
  },
  staging: {
    backend: 'https://api.stg.refari.co/',
    frontend: 'https://stg.refari.co/',
    widget: 'https://widget-stg.refari.co/' // widget
  },
  production: {
    backend: 'https://api.refari.co/',
    frontend: 'https://app.refari.co/',
    widget: 'https://widget.referrer.io/' // widget
  },
  production_new: {
    backend: 'https://api.refari.co/',
    frontend: 'https://app.refari.co/',
    widget: 'https://widget.refari.co/' // widget
  }
}

export const baseURLs = (location) => {
  switch (process.env.REACT_APP_BUILD_MODE) {
    case 'staging':
      return baseUrlConstants.staging[location]
    case 'production':
      return baseUrlConstants.production[location]
    case 'production_new':
      return baseUrlConstants.production_new[location]
    case 'development_old':
      return baseUrlConstants.development_old[location]
    default:
      /* development */
      return baseUrlConstants.development[location]
  }
}

export const getUserTypeUrlTag = (userType) => {
  if (userType === 'agency') return 'agency-user'
  if (userType === 'referrer') return 'referrer'
}

export const ApiRoutes = {
  baseURL: `${baseServerURLs()}api/v1/`,
  public: {
    common: 'public/common/'
  },
  auth: {
    signIn: 'auth/login/',
    signUp: 'auth/register/',
    logout: 'auth/logout/',
    emailVerify: 'auth/verify-email/',
    resendEmailVerify: 'auth/verify-email-resend/',
    forgotPasswordCreate: 'auth/password/reset/',
    forgotPasswordConfirm: 'auth/password/reset/confirm/',
    passwordChange: 'auth/password/change/',
    facebook: 'auth/facebook/',
    google: 'auth/google/',
    linkedin: 'auth/linkedin/',
    linkedinCallback: `${baseServerURLs()}auth/linkedin/`,
    facebookCallback: `${baseServerURLs()}auth/facebook/`,
    agencyUserInfo: 'auth/register/agency/userinfo/',
    referrerUserInfo: 'auth/register/referrer/userinfo/',
    hiringManagerUserInfo: 'auth/register/hiring-manager/userinfo/',
    agencyCreate: 'auth/register/agency/',
    referrerCreate: 'auth/register/referrer/',
    hiringManagerCreate: 'auth/register/hiring-manager/',
    twoFactor: {
      verify: 'auth/two-factor/verify/',
      backup: 'auth/two-factor/backup/',
      apps: {
        list: 'auth/two-factor/devices/apps/',
        delete: (id) => `auth/two-factor/devices/apps/${id}/`,
        qrcode: (id) => `auth/two-factor/devices/apps/${id}/qrcode/`,
        verify: (id) => `auth/two-factor/devices/apps/${id}/verify/`
      },
      phones: {
        list: 'auth/two-factor/devices/phones/',
        delete: (id) => `auth/two-factor/devices/phones/${id}/`,
        resend: (id) => `auth/two-factor/devices/phones/${id}/resend/`,
        verify: (id) => `auth/two-factor/devices/phones/${id}/verify/`
      }
    }
  },
  getApiKey: 'dashboard/agency/api-key/',
  googlefonts: 'https://www.googleapis.com/webfonts/v1/webfonts',
  profile: {
    me: 'profile/',
    update: (userType) => `profile/${getUserTypeUrlTag(userType)}/`,
    delete: 'profile/',
    avatar: (userType) => `profile/${getUserTypeUrlTag(userType)}/avatar/`,
    updateEmail: 'profile/agency-user/email/',
    resendEmailVerifyMail: 'profile/agency-user/email/resend/',
    hashtagsImg: 'dashboard/agency/hashtags/images/',
    exportData: 'profile/referrer/data/',
    video: (userType) => `profile/${getUserTypeUrlTag(userType)}/video/`
  },
  categories: {
    list: 'categories/',
    read: (id) => `categories/${id}/`
  },
  worktypes: {
    list: 'worktypes/',
    read: (id) => `worktypes/${id}/`
  },
  locations: {
    list: 'locations/',
    read: (id) => `locations/${id}/`
  },
  jobs: {
    list: 'jobs/',
    read: (id) => `jobs/${id}/`
  },
  applications: {
    apply: 'applications/apply/'
  },
  agency: {
    read: 'agency/',
    readRestricted: (id) => `public/agency/${id}/`,
    consultants: {
      list: 'agency/consultants/',
      aboutList: 'about/consultants/',
      progress: 'about/consultants/progress/',
      update: 'about/consultants/ordering/',
      read: (id) => `agency/consultants/${id}/`,
      jobs: (id) => `agency/consultants/${id}/jobs/`,
      rating: (id) => `agency/consultants/${id}/rating/`
    }
  },
  publicPosts: {
    create: (socialNetwork) => `public-posts/auth/${socialNetwork}/`,
    twitterAccessLink: 'public-posts/auth/twitter/access_link/',
    imageCreate: 'public-posts/images/',
    backgrounds: 'public-posts/backgrounds/',
    otherBackgrounds: 'public-posts/backgrounds/?obj_type=default',
    testimonialsBackground: 'public-posts/backgrounds/?obj_type=testimonial',
    readBackground: (id) => `/public-posts/backgrounds/${id}/`,
    postCreate: 'public-posts/posts/',
    fetchImage: (id) => `/public-posts/posts/${id}/`,
    handleCopy: (id) => `public-posts/publications/${id}/direct_publish/`,
    publish: (id) => `public-posts/posts/${id}/publish/`,
    publishCompanies: (id) => `public-posts/posts/${id}/publish-company/`,
    read: (id) => `public-posts/publications/${id}/`,
    getPublicationDetails: (id) => `public-posts/publications/${id}/published/`,
    directLinkCreate: 'public-posts/posts/direct_link/',
    preparePublish: (id) => `public-posts/posts/${id}/publish/`,
    sendInvitation: (id) => `public-posts/posts/${id}/invite/`,
    companiesList: 'public-posts/companies/',
    jobSimple: 'public-posts/posts/job_simple/',
    pitchMeCheck: 'public-posts/pitch-me/check/',
    pitchMeCreate: 'public-posts/pitch-me/',
    pitchMeRead: (id) => `public-posts/pitch-me/${id}/`,
    pitchMeDrafts: 'public-posts/pitch-me/drafts/',
    pitchMeDraftsUpdate: (id) => `public-posts/pitch-me/drafts/${id}/`
  },
  files: {
    coverLetter: 'files/coverletter/',
    resume: 'files/resume/',
    answer: 'files/answer/'
  },
  subscriptions: {
    read: (id) => `subscriptions/${id}/`,
    post: 'subscriptions/',
    delete: (id) => `subscriptions/${id}/`
  },
  shares: {
    create: 'shares/',
    read: (id) => `shares/${id}/`,
    rating: (id) => `shares/${id}/rating/`,
    email: 'shares/email/'
  },
  testimonials: {
    create: '/testimonials/',
    check: '/testimonials/check/',
    revision: (id) => `/testimonials/revisions/${id}/`,
    createTestimonialRevision: (id) => `/testimonials/${id}/revision/`,
    responses: (key) => `/testimonials/responses/${key}/`,
    checkTestimonial: (key) => `/testimonials/${key}/check/`,
    update: (id) => `/testimonials/${id}/`,
    approve: '/testimonials/approve/',
    inviteTestimonial: (key) => `/testimonials/invite/${key}/`,
    checkInviteTestimonial: (key) => `/testimonials/invite/${key}/check/`,
    profileList: (id, page) =>
      `agency/consultants/${id}/testimonials/?page=${page}`,
    readTestimonial: (consultantId, testimonialId) =>
      `agency/consultants/${consultantId}/testimonials/${testimonialId}`,
    readTestimonialLite: (testimonialId) =>
      `dashboard/testimonials/${testimonialId}/`
  },
  socializeWidget: {
    create: (socialNetwork) => `public-posts/auth/${socialNetwork}/`,
    twitterAccessLink: 'public-posts/auth/twitter/access_link/',
    imageCreate: 'public-posts/images/',
    backgrounds: 'public-posts/backgrounds/',
    postCreate: 'public-posts/posts/',
    fetchImage: (id) => `/public-posts/posts/${id}`,
    handleCopy: (id) => `public-posts/publications/${id}/direct_publish/`,
    publish: (id) => `public-posts/posts/${id}/publish/`,
    publishCompanies: (id) => `public-posts/posts/${id}/publish-company/`,
    read: (id) => `public-posts/publications/${id}/`,
    directLinkCreate: 'public-posts/posts/direct_link/',
    twitterDirectLinkCreate: (id) =>
      `public-posts/posts/${id}/publish-company-twitter/`,
    preparePublish: (id) => `public-posts/posts/${id}/publish/`,
    sendInvitation: (id) => `public-posts/posts/${id}/invite/`,
    companiesList: 'public-posts/companies/',
    jobSimple: 'public-posts/posts/job_simple/',
    pitchMeCheck: 'public-posts/pitch-me/check/',
    pitchMeCreate: 'public-posts/pitch-me/',
    pitchMeRead: (id) => `public-posts/pitch-me/${id}/`,
    refreshTokens: 'public-posts/personal/',
    scheduleSocialise: (id) => `public-posts/posts/${id}/schedule/`,
    generateAI: 'public-posts/posts/generate-ai/'
  },
  dashboard: {
    consultants: {
      list: 'dashboard/consultants/',
      create: 'dashboard/consultants/',
      invite: 'dashboard/consultants/invite/',
      read: (id) => `dashboard/consultants/${id}/`,
      delete: (id) => `dashboard/consultants/${id}/`,
      partialUpdate: (id) => `dashboard/consultants/${id}/`,
      resetPassword: (id) => `dashboard/consultants/${id}/reset_password/`
    },
    candidates: {
      getCandidatesList: 'dashboard/candidates/',
      getCandidateDetails: (id) => `dashboard/candidates/${id}`,
      getCandidateListLite: 'dashboard/candidates/lite',
      createNewCandidate: 'dashboard/candidates/',
      updateCandidate: (id) => `dashboard/candidates/${id}`,
      addReferrer: (id) => `dashboard/candidates/${id}/referral/`,
      partnerActions: (publicId, externalId, agencyId) =>
        agencyId
          ? `partner-actions/candidates/${publicId}/?external_id=${externalId}&agency=${agencyId}`
          : `partner-actions/candidates/${publicId}/?external_id=${externalId}`
    },
    candidateAd: {
      list: '/dashboard/candidate-ads/',
      create: '/dashboard/candidate-ads/',
      createDraft: '/dashboard/candidate-ads/draft/',
      editDraft: (id) => `/dashboard/candidate-ads/draft/${id}/`,
      expire: (id) => `/dashboard/candidate-ads/${id}`,
      details: (id) => `/dashboard/candidate-ads/${id}`,
      draftDetails: (id) => `/dashboard/candidate-ads/draft/${id}/`,
      publishDraft: (id) => `/dashboard/candidate-ads/draft/${id}/publish/`,
      partialUpdate: (id) => `dashboard/candidate-ads/${id}`,
      generateCandidateAdContent: 'dashboard/candidate-ads/generate-ai/',
      getCandidateFiles: 'dashboard/candidate-ads/files/',
      generateCandidateAdContentWithFileId:
        'dashboard/candidate-ads/generate-ai-by-file-id/',
      generateCandidateAdContentWithExternalFileID:
        'dashboard/candidate-ads/generate-ai-by-external-file-id/'
    },
    enquiries: {
      list: '/dashboard/hiring-manager/enquiries/',
      detauls: (id) => `/dashboard/hiring-manager/enquiries/${id}/`
    },
    toprec: {
      consultants: 'dashboard/reports/consultants/',
      testimonials: {
        list: 'dashboard/reports/testimonials/',
        chart: 'dashboard/reports/testimonials/chart/',
        wordcloud: 'dashboard/reports/testimonials/wordcloud/'
      },
      settings: 'dashboard/agency/top-rec-settings/',
      googleBusinessAccounts: 'dashboard/agency/google-business/',
      googleBusinessAccountDetail: (id) =>
        `dashboard/agency/google-business/${id}/`,
      googleBusinessAuthLink: 'dashboard/agency/google-business/auth-link/',
      googleBusinessAccountSync: (id) =>
        `dashboard/agency/google-business/${id}/sync/`,
      googleRatings: 'dashboard/reports/google-reviews/'
    },
    placements: {
      testimonials: {
        import: 'dashboard/placements/import/',
        list: 'dashboard/placements/testimonials/',
        send: 'dashboard/placements/testimonials/send/'
      },
      invite: 'dashboard/placements/invite/'
    },
    floatingLink: 'dashboard/agency/cta/',
    agencyLite: 'dashboard/agency/lite/',
    reviews: {
      list: 'dashboard/reviews/',
      googleReviewsList: 'dashboard/reviews/google/',
      emailPreview: 'dashboard/reviews/responses/email-preview/',
      emailRevisionRequest: 'dashboard/reviews/revisions/email-preview/',
      read: (id) => `dashboard/reviews/${id}/`,
      readGoogleReview: (id) => `dashboard/reviews/google/${id}/`,
      partialUpdate: (id) => `dashboard/reviews/${id}/`,
      partialUpdateGoogleReview: (id) => `dashboard/reviews/google/${id}/`,
      comments: 'dashboard/reviews/comments/',
      updateComment: (id) => `dashboard/reviews/comments/${id}/`,
      responses: 'dashboard/reviews/responses/',
      testimonialRevision: 'dashboard/reviews/revisions/'
    },
    referrers: {
      list: 'dashboard/referrers/',
      read: (id) => `dashboard/referrers/${id}/`,
      addToCRM: (id) => `dashboard/referrers/${id}/add_to_crm/`,
      report: (id) => `dashboard/referrers/${id}/report/`,
      csv: (dateFrom, dateTo) =>
        dateFrom && dateTo
          ? `dashboard/referrers/csv/?date_from=${dateFrom}&date_to=${dateTo}`
          : 'dashboard/referrers/csv/',
      partialUpdate: (id) => `dashboard/referrers/${id}/`
    },
    rewards: {
      read: 'dashboard/agency/worktypes/'
    },
    applications: {
      list: 'dashboard/candidate-events/',
      read: (id) => `dashboard/candidate-events/${id}/`,
      resendToATS: (id) => `dashboard/candidate-events/${id}/send_to_ats/`,
      // apply: (id) => `dashboard/applications/${id}/apply/`,
      // duplicate: (id) => `dashboard/applications/${id}/duplicate/`,
      rating: (id) => `dashboard/candidate-events/${id}/rate/`
      // addToCRM: (id) => `dashboard/applications/${id}/add_to_crm/`
    },
    referrals: {
      list: 'dashboard/referrer/referral-events/',
      read: (id) => `dashboard/referrer/referral-events/${id}/`
    },
    jobReferrals: {
      list: 'dashboard/referrer/job-share/',
      read: (id) => `dashboard/referrer/job-share/${id}/`
    },
    appliedJobs: {
      list: 'dashboard/referrer/applications/',
      read: (id) => `dashboard/referrer/applications/${id}/`
    },
    jobAlerts: {
      list: 'dashboard/subscriptions/',
      partialUpdate: (id) => `dashboard/subscriptions/${id}/`,
      delete: (id) => `dashboard/subscriptions/${id}/`,
      partnerActions: (publicId, externalId, agencyId) =>
        agencyId
          ? `partner-actions/candidates/${publicId}/?external_id=${externalId}&agency=${agencyId}`
          : `partner-actions/candidates/${publicId}/?external_id=${externalId}`
    },
    featured: {
      list: 'dashboard/referrer/jobs/featured/',
      delete: (id) => `dashboard/referrer/jobs/featured/${id}/`
    },
    agency: {
      read: 'dashboard/agency/',
      widgetRead: 'agency/',
      update: 'dashboard/agency/',
      currency: 'dashboard/agency/currencies/',
      getTags: 'dashboard/agency/consultant-tags/',
      updateTags: 'dashboard/agency/consultant-tags/',
      switchUserType: 'profile/switch-account/',
      switchAgency: 'profile/switch-agency/',
      loginAs: (id) => `dashboard/consultants/${id}/login_as/`,
      hashtags: 'dashboard/agency/hashtags/',
      updateHashtags: (id) => `dashboard/agency/hashtags/${id}/`,
      testimonials: '/dashboard/consultant/testimonials/',
      testimonialsEdit: (id) => `/dashboard/consultant/testimonials/${id}/`,
      companyTestimonials: '/dashboard/agency/testimonials/',
      companyTestimonialsEdit: (id) => `/dashboard/agency/testimonials/${id}/`,
      crmStatuses: 'dashboard/agency/ats/statuses/'
    },
    agencyBranding: {
      read: 'dashboard/agency/branding/',
      update: 'dashboard/agency/branding/',
      logo: 'files/branding-logo/'
    },
    backgrounds: {
      list: 'dashboard/agency/backgrounds/',
      read: (id) => `dashboard/agency/backgrounds/${id}/`,
      update: (id) => `dashboard/agency/backgrounds/${id}/`,
      delete: (id) => `dashboard/agency/backgrounds/${id}/`,
      clone: (id) => `dashboard/agency/backgrounds/${id}/clone/`,
      preview: 'dashboard/agency/backgrounds/preview/'
    },
    socialize: {
      list: 'dashboard/socialize/',
      read: (id) => `dashboard/socialize/${id}/`,
      update: (id) => `dashboard/socialize/${id}/`,
      cancelScheduledSocialise: (id) => `dashboard/socialize/${id}/cancel/`
    },
    jobOrders: {
      list: 'dashboard/job-orders/',
      partnerActions: (publicId, externalId, agencyId) =>
        agencyId
          ? `partner-actions/job-orders/${publicId}/?external_id=${externalId}&agency=${agencyId}`
          : `partner-actions/job-orders/${publicId}/?external_id=${externalId}`
    },
    jobs: {
      search: (name) => `/jobs/?search=${name}`,
      social: 'dashboard/agency/social/links/'
    },
    jobAds: {
      list: 'dashboard/job-ads/',
      featured: 'dashboard/job-ads/agency-featured/',
      read: (id) => `dashboard/job-ads/${id}/`,
      sync: (id) => `dashboard/job-ads/${id}/sync/`,
      update: (id) => `dashboard/job-ads/${id}/`,
      partnerActions: (publicId, externalId, agencyId) =>
        agencyId
          ? `partner-actions/job-ads/${publicId}/?external_id=${externalId}&agency=${agencyId}`
          : `partner-actions/job-ads/${publicId}/?external_id=${externalId}`
    },
    activities: {
      list: 'dashboard/logs/activity/'
    },
    communications: {
      list: 'dashboard/logs/emails-no-pages/',
      read: (id) => `dashboard/logs/emails/${id}/`
    },
    systemAdmin: {
      users: {
        list: 'dashboard/system-admin/users/',
        delete: (id) => `dashboard/system-admin/users/${id}/`,
        loginAs: (id) => `dashboard/system-admin/users/${id}/login_as/`,
        resetPassword: (id) =>
          `dashboard/system-admin/users/consultants/${id}/reset_password/`
      },
      recruiters: {
        list: 'dashboard/system-admin/users/consultants/',
        create: 'dashboard/system-admin/users/consultants/',
        read: (id) => `dashboard/system-admin/users/consultants/${id}/`,
        update: (id) => `dashboard/system-admin/users/consultants/${id}/`,
        delete: (id) => `dashboard/system-admin/users/consultants/${id}/`,
        loginAs: (id) =>
          `dashboard/system-admin/users/consultants/${id}/login_as/`,
        invite: (id) => `dashboard/system-admin/users/consultants/${id}/invite/`
      },
      reports: {
        list: (report) => `dashboard/system-admin/reports/${report}/`
      },
      reportsSource: {
        list: (report) => `dashboard/system-admin/reports-source/${report}/`,
        agencies: 'dashboard/system-admin/reports-source/agencies/'
      },
      agencies: {
        list: 'dashboard/system-admin/agencies/'
      },
      gamification: {
        list: 'dashboard/system-admin/gamification/leaders/',
        progress: 'dashboard/system-admin/gamification/progress/',
        aliasLevel: (category_alias, level, page_value) =>
          `dashboard/system-admin/gamification/progress/${category_alias}/${level}/?page=${page_value}`,
        aliasLevelDatesOfAchievement: (
          category_alias,
          level,
          progress_id,
          page_value
        ) =>
          `dashboard/system-admin/gamification/progress/${category_alias}/${level}/${progress_id}/?page=${page_value}`
      },
      billing: {
        list: 'dashboard/system-admin/billing/',
        filterList: (agencyID) =>
          `dashboard/system-admin/billing/?agency=${agencyID}`,
        predictorDataDownload: 'dashboard/system-admin/billing/pdf/'
      },
      erase: 'dashboard/system-admin/erase/',
      eraseLink: 'dashboard/system-admin/erase/link/',
      clients: {
        list: 'dashboard/system-admin/clients/'
      },
      systemCta: 'dashboard/agency/system-cta/'
    },
    referrer: {
      invite: 'dashboard/referrers/invite/'
    },
    emailMapRead: 'dashboard/agency/email-map/',
    xml: {
      list: (page) => `dashboard/agency/xmls/?page=${page}`,
      remove: (id) => `dashboard/agency/xmls/${id}/`,
      update: (id) => `dashboard/agency/xmls/${id}/`,
      path: 'dashboard/agency/xmls/'
    },
    questions: {
      list: 'dashboard/questionnaires/job-ads/',
      clone: (id) => `dashboard/questionnaires/questions/${id}/clone/`,
      update: (id) => `dashboard/questionnaires/questions/${id}/`,
      updateMapping: (id) => `dashboard/questionnaires/job-ads/${id}/mapping/`,
      jobs: (id) => `dashboard/questionnaires/questions/${id}/jobs/`,
      questionSetJobs: (id) =>
        `dashboard/questionnaires/question-sets/${id}/jobs/`,
      registrationList: 'dashboard/questionnaires/registration/',
      registrationUpdate: (id) =>
        `dashboard/questionnaires/registration/${id}/`,
      registrationUpdateMapping: (id) =>
        `dashboard/questionnaires/registration/${id}/mapping/`,
      questionsList: 'dashboard/questionnaires/questions/',
      questionsJobsList: 'dashboard/questionnaires/questions-jobs/',
      questionsSetList: 'dashboard/questionnaires/question-sets/',
      questionsSetListLite: 'dashboard/questionnaires/question-sets/lite',
      questionsSetUpdate: (id) =>
        `dashboard/questionnaires/question-sets/${id}/`,
      questionsSetPreview: (page) =>
        `dashboard/questionnaires/question-sets/preview/?page=${page}`
    }
  },
  suggestions: {
    create: 'suggestions/',
    accept: (id) => `suggestions/${id}/accept/`,
    messages: 'suggestions/messages/',
    createMessageTemplate: 'suggestions/templates/',
    readMessageTemplates: `suggestions/templates/`,
    readMessageTemplateDetails: (id) => `suggestions/templates/${id}/`,
    updateMessageTemplate: (id) => `suggestions/templates/${id}/`,
    deleteMessageTemplate: (id) => `suggestions/templates/${id}/`,
    emailPreview: 'suggestions/email-preview/',
    fetch: (id) => `suggestions/${id}/`,
    edit: (id) => `suggestions/${id}/partial_accept/`
  },
  billing: {
    account: {
      read: 'dashboard/billing/account/',
      update: 'dashboard/billing/account/',
      billingAddress: 'dashboard/billing/account/billing_address/',
      createCard: 'dashboard/billing/account/cards/'
    },
    invoices: {
      list: 'dashboard/billing/invoices/',
      read: (id) => `dashboard/billing/invoices/${id}/`,
      acceptCheck: (id) => `dashboard/billing/invoices/${id}/accept-check/`,
      accept: (id) => `dashboard/billing/invoices/${id}/accept/`,
      reject: (id) => `dashboard/billing/invoices/${id}/reject/`
    },
    payments: {
      list: 'dashboard/billing/payments/',
      transactions: (id) => `dashboard/billing/payments/${id}/transactions/`,
      consultants: (id) => `dashboard/billing/payments/${id}/consultants/`,
      transaction: (id) => `dashboard/billing/payments/${id}/`,
      pay: (id) => `dashboard/billing/payments/${id}/pay/`,
      read: (id) => `/dashboard/billing/payments/${id}/`,
      loadFile: (id) => `/dashboard/billing/payments/${id}/pdf/`
    },
    budget: {
      agencyBudget: '/dashboard/agency/ai-budget/',
      listRecruiters: '/dashboard/agency/ai-budget/recruiters/',
      updateRecruiters: (id) => `/dashboard/agency/ai-budget/recruiters/${id}/`
    }
  },
  gamefication: {
    agencyLeadersList: 'dashboard/achievements/leaderboard/',
    leaders: 'dashboard/achievements/leaders/',
    rules: 'dashboard/achievements/rules/',
    progress: 'dashboard/achievements/progress/',
    updateProgress: '/dashboard/achievements/progress/new/',
    listDatesOfAchievement: (category_alias, level, page_value) =>
      `dashboard/achievements/progress/${category_alias}/${level}/?page=${page_value}`
  },
  mailing: {
    create: 'mailing-list/'
  },
  flatPages: {
    read: (name) => `/flatpages/${name}/`,
    contactUs: '/contact-us/'
  },
  socialCompanies: {
    /**
     * @urgent social-companies/* is deleted
     * @todo find are these endpoints are used anywhere else
     */
    list: 'social-companies/',
    update: (id) => `social-companies/${id}/`,
    partialUpdate: (id) => `social-companies/${id}/`,
    createConnection: (network) => `social-companies/${network}/`,
    unlink: (network, id) => `social-companies/${network}/${id}/`,
    twitterAccessLink: 'social-companies/twitter/access_link/'
  },
  socialNetworks: {
    personal: {
      list: 'dashboard/social-networks/personal/',
      createConnection: 'dashboard/social-networks/personal/linkedin/',
      twitterAccessLink: 'dashboard/social-networks/personal/twitter/',
      unlink: (accountId) => `dashboard/social-networks/personal/${accountId}/`
    },
    company: {
      list: 'dashboard/social-networks/company/',
      createConnection: 'dashboard/social-networks/company/linkedin/',
      createFacebookConnection: 'dashboard/social-networks/company/facebook/',
      twitterAccessLink: 'dashboard/social-networks/company/twitter/',
      unlink: (accountId) => `dashboard/social-networks/company/${accountId}/`,
      companyToggle: (accountId, companyId) =>
        `dashboard/social-networks/company/${accountId}/${companyId}/`
    }
  },
  demo: {
    request: {
      create: '/demo/requests/'
    },
    integrations: 'demo/integrations/'
  },
  subscription: {
    categories: 'categories/',
    locations: 'locations/',
    worktypes: 'worktypes/',
    currencies: 'currencies/',
    selectedCategories: (value) => `categories/?categories=${value}`,
    // selectedCategories: (category, prefix_category) =>
    //   `categories-filters/?category=${category}&prefix_category=${prefix_category}`,
    selectedLocations: (value) => `locations/?locations=${value}`,
    selectedWorktypes: (value) => `worktypes/?worktypes=${value}`
  },
  // see https://api.dev.refari.co/docs/#standalone
  standalone: {
    jobAds: {
      read: (jobId) => `/standalone/job-ads/${jobId}/`,
      update: (jobId) => `/standalone/job-ads/${jobId}/`,
      partialUpdate: (jobId) => `/standalone/job-ads/${jobId}/`,
      mediaTags: (jobId, searchTag) =>
        `/standalone/job-ads/${jobId}/media-tags/?search=${searchTag}`,
      questionSets: (jobId, searchTag) =>
        `/standalone/job-ads/${jobId}/question-sets/?search=${searchTag}`,
      questionSetsPreview: (questionSetID, currentPage) =>
        `/standalone/job-ads/question-sets/${questionSetID}/preview/?page=${
          currentPage || 1
        }`,
      repost: (jobId) => `/standalone/job-ads/${jobId}/repost/`,
      validateReferralRewardFee: '/standalone/job-ads/validate-reward/'
    },
    download: 'standalone/filter-ids/download/',
    sendData: 'standalone/filter-ids/send/',
    list: {
      locations: '/standalone/locations/',
      candidateLocations: '/standalone/candidate-locations/',
      recruiters: '/standalone/recruiters/',
      worktypes: '/standalone/worktypes/',
      candidateWorktypes: '/standalone/candidate-worktypes/',
      categories: '/standalone/categories/',
      candidateSkills: '/standalone/candidate-skills/',
      availableCountries: '/standalone/detailed-locations/countries/all/',
      countries: '/standalone/detailed-locations/countries/',
      createDetailedCountry: '/standalone/detailed-locations/countries/',
      deleteDetailedCountry: (id) =>
        `/standalone/detailed-locations/countries/${id}/`,
      states: '/standalone/detailed-locations/states/',
      createDetailedState: '/standalone/detailed-locations/states/',
      cities: '/standalone/detailed-locations/cities/',
      createDetailedCity: '/standalone/detailed-locations/cities/'
    },
    importLocations: '/standalone/candidate-locations/import-job-board/',
    importWorktypes: 'standalone/candidate-worktypes/import-job-board/'
  },
  talentAlert: {
    subscription: {
      create: '/talent-alerts/subscriptions/',
      locations: '/talent-alerts/locations/',
      worktypes: '/talent-alerts/worktypes/',
      skills: '/talent-alerts/skills/',
      selectedLocations: (value) =>
        `/talent-alerts/locations/?locations=${value}`,
      selectedWorktypes: (value) =>
        `/talent-alerts/worktypes/?worktypes=${value}`,
      selectedSkills: (value) => `/talent-alerts/skills/?skills=${value}`
    },
    suggestion: {
      create: '/talent-alerts/suggestions/',
      fetch: (id) => `/talent-alerts/suggestions/${id}/`,
      createMessageTemplate: '/talent-alerts/suggestions/templates/',
      readMessageTemplates: `/talent-alerts/suggestions/templates/`,
      readMessageTemplateDetails: (id) =>
        `/talent-alerts/suggestions/templates/${id}/`,
      updateMessageTemplate: (id) =>
        `/talent-alerts/suggestions/templates/${id}/`,
      deleteMessageTemplate: (id) =>
        `/talent-alerts/suggestions/templates/${id}/`,
      emailPreview: '/talent-alerts/suggestions/email-preview/',
      edit: (id) => `/talent-alerts/suggestions/${id}/partial_accept/`
    },
    dashboard: {
      list: 'dashboard/hiring-manager/talent-alerts/subscriptions/',
      read: (id) =>
        `dashboard/hiring-manager/talent-alerts/subscriptions/${id}/`,
      partialUpdate: (id) =>
        `dashboard/hiring-manager/talent-alerts/subscriptions/${id}/`,
      delete: (id) =>
        `dashboard/hiring-manager/talent-alerts/subscriptions/${id}/`
    }
  }
}
